<template>
  <div class="pt-10">
    <div class="vx-col  w-full mb-base">
      <vx-card>
        <div class="flex items-center justify-between">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex items-end">
                <a href="javascript:void(0)" class>Manage</a>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right w-4 h-4"
                    >
                      <polyline points="13 17 18 12 13 7"></polyline>
                      <polyline points="6 17 11 12 6 7"></polyline>
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex items-end">
                <router-link :to="{ name: 'center-admin-children-list' }"
                  >Children</router-link
                >
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right w-4 h-4"
                    >
                      <polyline points="13 17 18 12 13 7"></polyline>
                      <polyline points="6 17 11 12 6 7"></polyline>
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex items-center">
                <a href="javascript:void(0)" class="">Add new Parent</a>
              </li>
            </ul>
          </div>
        </div>
        <vs-divider class="mb-0 md:block hidden"></vs-divider>

        <div class="w-full md:w-1/2">
          <!-- Title -->
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Title</label>
            <v-select
              :options="titleOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="titleFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Title"
            />
          </div>

          <vs-input
            label="Full Name"
            v-model="fullName"
            class="mt-5 w-full"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Full Name"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{
            errors.first("fullName")
          }}</span>

          <vs-input
            class="w-full mt-5"
            v-model="email"
            name="email"
            v-validate="'required|email'"
            label="Email"
            data-vv-as="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
          
          <vs-input
            label="Relation To Child"
            v-model="relationToChild"
            class="mt-5 w-full"
            name="relationToChild"
            v-validate="'required'"
            data-vv-as="Relation To Child"
          />
          <span class="text-danger text-sm" v-show="errors.has('relationToChild')">{{
            errors.first("relationToChild")
          }}</span>

          <vs-input
            label="Mobile Number"
            v-model="contactNumber"
            class="mt-5 w-full"
            name="contactNumber"
            data-vv-as="Mobile Number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('contactNumber')"
            >{{ errors.first("contactNumber") }}</span
          >
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Country</label>
            <v-select
              :options="countryOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="countryFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Country"
            />
          </div>

          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">State</label>
            <v-select
              :options="stateOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="stateFilter"
              class="mb-4 md:mb-0"
              data-vv-as="State"
            />
          </div>
          <vs-input
            label="Street Address"
            v-model="streetAddress"
            class="mt-5 w-full"
            name="streetAddress"
            data-vv-as="Street Address"
          />

          <vs-input
            label="Town/City"
            v-model="town"
            class="mt-5 w-full"
            name="town"
            data-vv-as="Town"
          />

          <vs-input
            label="Postcode"
            v-model="postCode"
            class="mt-5 w-full"
            name="postCode"
            data-vv-as="Post Code"
          />
          <span class="text-danger text-sm" v-show="errors.has('postCode')">{{
            errors.first("postCode")
          }}</span>



           <label class="vs-input--label mt-5">Account Status</label>
          <vs-switch v-model="status"/>
          {{ status ? 'Active' : 'Inactive' }}



        </div>
        <div class="flex flex-wrap items-center pt-6 w-full md:w-1/2">
          <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
          <vs-button
            class="ml-auto"
            @click="submitData"
            :disabled="!isFormValid"
            >Save</vs-button
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      fullName: "full name",
      contactNumber: "mobile number"
    }
  }
};

Validator.localize(dictionary);
export default {
  data() {
    return {
      dataId: null,
      directorId: "",
      status: true,
      relationToChild: "",
      titleOptions: [
        { label: "Mr.", value: "Mr." },
        { label: "Mrs.", value: "Mrs." },
        { label: "Miss.", value: "Miss." }
      ],
      fullName: "",
      contactNumber: "",
      email: "",
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      streetAddress: "",
      town: "",
      postCode: "",
      countryOptions: [],
      stateOptions: [],
      titleFilter: { label: "Select Title", value: "" },
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      customError: "",
      learningCenterId: this.$route.params.id
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    centerId: {
      type: String,
      default: ""
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    }
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() && this.fullName && this.email
      );
    },
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorTeacher",
      "addDirectorTeacher",
      "imageUpload",
      "getDirectorAllCountries"
    ]),
    ...mapActions("centerAdmin", ["addCenterAdminTeacher"]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("title", this.titleFilter.value);
          data.append("fullName", this.fullName);
          data.append("relationToChild", this.relationToChild);
          data.append("contactNumber", this.contactNumber);
          data.append("streetAddress", this.streetAddress);
          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("state", this.stateFilter.value);
          data.append("directorId", this.centerAdmin.learningCenter.directorId);
          data.append("learningCenterId", this.centerAdmin.learningCenterId);
          data.append("email", this.email);
          data.append('status', this.status);
          data.append("userType", "parent");
          data.append("role", "parent");

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorTeacher(data)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Parent Updated successfully.",
                    "success"
                  );
                  data.country = this.countryFilter.value;
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
              });
          } else {
            this.$vs.loading();
            this.addCenterAdminTeacher(data)
              .then(res => {
                this.$vs.loading.close();
                this.$emit("refreshData", true);
                this.$vs.notify({
                  title: "Success",
                  text: "Parent added successfully.",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success"
                });
                this.$router.push({ name: "center-admin-children-add" });
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response && err.response.status === 422) {
                  this.errors.add({
                    field: "email",
                    msg: err.response.data.message
                  });

                  this.$vs.notify({
                    title: "Failure",
                    text: err.response.data.message,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger"
                  });
                } else {
                  this.$vs.notify({
                    title: "Failure",
                    text: "Can not add Parent",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger"
                  });
                }
              });
          }
        }
      });
    },
    cancelBtn() {
      this.$router.push({ name: "director-children-add" });
    }
  },
  created() {
    this.getDirectorAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {});
  },
  components: {
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
